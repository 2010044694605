import styles from "./Footer.module.css";

import { Link } from "react-router-dom";
import { ImLocation } from "react-icons/im";
import { IoCall } from "react-icons/io5";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.group}>
          <h3>Services</h3>
          <nav>
            <ul>
              <li>
                <Link to="/services">All Services</Link>
              </li>
              <li>
                <Link to="/services/cosmetic-dentistry">Cosmetic</Link>
              </li>
              <li>
                <Link to="/services/general-and-restorative-dentistry">
                  General And Restorative
                </Link>
              </li>
              <li>
                <Link to="/services/implant-dentistry">Implant</Link>
              </li>
              <li>
                <Link to="/services/oral-surgery">Oral Surgery</Link>
              </li>
              <li>
                <Link to="/services/orthodontics">Orthodontics</Link>
              </li>
              <li>
                <Link to="/services/prosthodontics">Prosthodontics</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className={styles.group}>
          <h3>Practice</h3>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/request-appointment">Request Appointment</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className={styles.group}>
          <h3>Office</h3>
          <p>2434 St.Clair Ave W,</p>
          <p>Toronto, ON, M6N 1L2</p>
          <a
            href="https://goo.gl/maps/C1S5vkKMhKD47fqR7"
            target="_blank"
            rel="noreferrer"
          >
            <ImLocation /> Get Direction
          </a>
          <br />
          <a href="tel:4168386035">
            <IoCall /> Call (416) 838 - 6035
          </a>
        </div>
        <div className={styles.group}>
          <h3>Review Us</h3>
          <a
            href="https://search.google.com/local/writereview?placeid=ChIJP-w11jM3K4gR73j3ce98MFM"
            target="_blank"
            rel="noreferrer"
          >
            Google
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
