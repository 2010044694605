import { Link, useNavigate } from "react-router-dom";
import styles from "./ServiceTile.module.css";

const ServiceTile = ({ service }) => {
  const navigate = useNavigate();

  return (
    <article
      className={styles.container}
      onClick={() => navigate("/services/" + service.id)}
    >
      <img
        className={styles.image}
        src={"/images" + service.imgLink}
        alt={service.title}
      />
      <h3>{service.title}</h3>
      <Link to={"/services/" + service.id} className={styles.button}>
        LEARN MORE
      </Link>
    </article>
  );
};

export default ServiceTile;
