import styles from "./ContactPage.module.css";
import Form from "./Form";

const ContactPage = ({ type }) => {
  return (
    <div className={styles.container}>
      <div className={styles.coverImage}></div>
      <div className={styles.main}>
        <div className={styles.imageContainer}>
          <img
            src="/images/general/contact.jpg"
            alt="A receptionist answering the phone"
          />
        </div>
        <div className={styles.formContainer}>
          <Form type={type} />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
