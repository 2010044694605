import styles from "./HomePage.module.css";
import HomePageEntry from "./HomePageEntry";
import { TbDental } from "react-icons/tb";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { ReactComponent as HearIcon } from "../assets/icons/heart-icon.svg";
import { ReactComponent as CalendarIcon } from "../assets/icons/calendar-icon.svg";
import { ReactComponent as PricingIcon } from "../assets/icons/pricing-icon.svg";
import { ReactComponent as DoorIcon } from "../assets/icons/door-icon.svg";

const HomePage = () => {
  return (
    <div className={styles.container}>
      <HomePageEntry />
      <div className={styles.banner}>
        <h2>Expert Care For Lifetime Smiles</h2>
        <ul className={styles.bannerList}>
          <li>
            <HearIcon />
            <div>
              <h3>Comfort</h3>
              <p>Gentle hands, neighborly experience</p>
            </div>
          </li>
          <li>
            <CalendarIcon />
            <div>
              <h3>Convenience</h3>
              <p>Easy scheduling, no long waits</p>
            </div>
          </li>
          <li>
            <PricingIcon />
            <div>
              <h3>Transparency</h3>
              <p>Crystal clear pricing, honest care</p>
            </div>
          </li>
          <li>
            <DoorIcon />
            <div>
              <h3>Welcoming</h3>
              <p>Accepting new patients of all ages, from kids to adults</p>
            </div>
          </li>
        </ul>
      </div>
      <h2 className={styles.servicesMobileHeader}>Our Services</h2>
      <div className={styles.services}>
        <div className={styles.imageContainer}>
          <img src="/images/general/brush-teeth.jpg" alt="People smiling" />
        </div>
        <ul className={styles.serviceList}>
          <li>
            <h2>Our Services</h2>
          </li>
          <li>
            <TbDental /> <span>Cosmetic Dentistry</span>
          </li>
          <li>
            <TbDental /> <span>General And Restorative Dentistry</span>
          </li>
          <li>
            <TbDental /> <span>Implant Dentistry</span>
          </li>
          <li>
            <TbDental /> <span>Oral Surgery</span>
          </li>
          <li>
            <TbDental /> <span>Orthodontics</span>
          </li>
          <li>
            <TbDental /> <span>Prosthodontics</span>
          </li>
          <li>
            <Link to="/services" className={styles.toServicesLink}>
              <HiOutlineArrowNarrowRight />
              Learn more <div className={styles.linkLine}></div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HomePage;
