import { useParams } from "react-router-dom";
import styles from "./ServicePage.module.css";
import { services } from "../data";
import Services from "./Services";

const ServicePage = () => {
  const { id } = useParams();

  const renderInfo = () => {
    return services[id].details.map((detail) => <li key={detail}>{detail}</li>);
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{services[id].title}</h2>
      <div className={styles.infoContainer}>
        <div className={styles.imageContainer}>
          <img
            src={"/images" + services[id].imgLink}
            alt={`${services[id].title} demo`}
          />
        </div>
        <ul className={styles.info}>{renderInfo()}</ul>
      </div>
      <Services title="Other Services" currentService={id} />
    </div>
  );
};

export default ServicePage;
