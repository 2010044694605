import styles from "./HomePageEntry.module.css";
import { Link } from "react-router-dom";

const HomePageEntry = () => {
  return (
    <div className={styles.entry}>
      <div className={styles.entryImage}>
        <img src="/images/general/home-page-entry.jpg" alt="smiling girl" />
      </div>
      <div className={styles.text}>
        <p>Welcome to Phildon Dental!</p>
        <h1>
          Change your smile. <br /> Change your life.
        </h1>
        <p>Do you love how your smile makes you look and feel?</p>
        <div className={styles.links}>
          <Link to="/request-appointment" className="linkBtn scheduleBtn">
            Schedule Now
          </Link>
          <a href="tel:4168386035" className="linkBtn">
            Call (416) 838 - 6035
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomePageEntry;
