import styles from "./Header.module.css";

import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import Menu from "./Menu";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (showMenu) {
        setShowMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [showMenu]);

  const hideMenu = () => setShowMenu(false);

  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <>
      <div className={styles.header}>
        <nav className={styles.navBar}>
          <div className={styles.logo}>
            <Link to="/">
              <img src="/images/general/full-logo.png" alt="Don Dental Logo" />
            </Link>
          </div>
          <div className={styles.desktopMenu}>
            <Menu hideMenu={hideMenu} />
          </div>
          <div className={styles.menuIcon} onClick={toggleMenu}>
            <GiHamburgerMenu />
          </div>
        </nav>
      </div>
      <div className={`${styles.mobileMenu} ${showMenu ? styles.show : ""}`}>
        <Menu hideMenu={hideMenu} />
        <div className="borderLine"></div>
      </div>
    </>
  );
};

export default Header;
