import styles from "./OfficeInfo.module.css";

import { ImLocation } from "react-icons/im";
import { IoCall } from "react-icons/io5";
import { Link } from "react-router-dom";

const OfficeInfo = () => {
  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <div className={styles.info}>
          <h2>Our Office</h2>
          <hr />
          <p>2434 St.Clair Ave W</p>
          <p>Toronto, ON, M6N 1L2</p>
          <a
            href="https://goo.gl/maps/C1S5vkKMhKD47fqR7"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            <ImLocation />
            <span>Get Direction</span>
          </a>
          <a href="tel:4168386035" className={styles.link}>
            <IoCall />
            <span>Call (416) 838 - 6035</span>
          </a>
          <Link to="/request-appointment" className="linkBtn scheduleBtn">
            Schedule Now
          </Link>
        </div>
        <div className={styles.hours}>
          <h2>Hours</h2>
          <hr />
          <div className={styles.table}>
            <div className={styles.days}>
              <p>Monday</p>
              <p>Tuesday</p>
              <p>Wednesday</p>
              <p>Thursday</p>
              <p>Friday</p>
              <p>Saturday</p>
              <p>Sunday</p>
            </div>
            <div className={styles.time}>
              <p>08:00am - 06:00pm</p>
              <p>08:00am - 06:00pm</p>
              <p>08:00am - 06:00pm</p>
              <p>08:00am - 06:00pm</p>
              <p>08:00am - 06:00pm</p>
              <p>08:00am - 06:00pm</p>
              <p>Closed</p>
            </div>
          </div>
        </div>
      </div>
      <iframe
        className={styles.map}
        title="Phil Don Dental Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11543.910070079879!2d-79.4827845!3d43.6694374!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x53307cef71f778ef!2sPhildon%20Dental!5e0!3m2!1sen!2sus!4v1662101246070!5m2!1sen!2sus"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default OfficeInfo;
