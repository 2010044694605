import { useState } from "react";
import styles from "./Form.module.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { AiOutlineCheck } from "react-icons/ai";

const INITIAL_WARNING = {
  firstName: false,
  lastName: false,
  email: false,
  phone: false,
  message: false,
};

const Form = ({ type }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [message, setMessage] = useState("");
  const [warning, setWarning] = useState(INITIAL_WARNING);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const location = useLocation();

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setDate("");
    setTime("");
    setMessage("");
    setWarning({ ...INITIAL_WARNING });
  };

  useEffect(() => {
    clearForm();
  }, [location]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (isSending) {
        setIsSent(true);
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [isSending]);

  const isGoodForm = () => {
    const WARNING_FIELDS = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "message",
    ];
    const newWarning = { ...INITIAL_WARNING };
    // validate firstName
    if (firstName.trim().length === 0) {
      newWarning.firstName = true;
    }
    // validate lastName
    if (lastName.trim().length === 0) {
      newWarning.lastName = true;
    }
    // validate email
    const emailRegex = new RegExp(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    );
    if (email.trim().length === 0 || !emailRegex.test(email)) {
      newWarning.email = true;
    }
    // validate phone number
    if (phone.length < 16) {
      newWarning.phone = true;
    }
    // validate message
    if (message.trim().length === 0) {
      newWarning.message = true;
    }

    setWarning({ ...newWarning });

    for (let field of WARNING_FIELDS) {
      if (newWarning[field]) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isGoodForm()) {
      setIsSubmitted(true);

      const dateObj = new Date();
      const month = dateObj.getMonth() + 1;
      const todayDate = dateObj.getDate();
      const year = dateObj.getFullYear();

      const subject = `${
        type === "appointment" ? "Appointment Request" : "Contact Us Message"
      } - ${firstName} ${lastName} - ${month}/${todayDate}/${year}`;

      const data = {
        "First Name": firstName,
        "Last Name": lastName,
        "Email Address": email,
        "Phone Number": phone,
        Message: message,
        _subject: subject,
      };

      if (type === "appointment") {
        data["Preferred Appointment Date"] = date;
        data["Preferred Appointment Time"] = time;
      }

      await axios.post("https://formsubmit.co/ajax/dr@phildon.dentist", data);

      clearForm();

      setIsSending(true);
    }
  };

  const toNumberOnly = (arr) => {
    const regex = new RegExp(/\d/);
    return arr.filter((char) => regex.test(char));
  };

  const formatPhoneNumber = (digits) => {
    if (digits.length >= 1) {
      digits.splice(0, 0, "(");
    }

    if (digits.length >= 5) {
      digits.splice(4, 0, ") ");
    }

    if (digits.length >= 9) {
      digits.splice(8, 0, " - ");
    }

    if (digits.length > 13) {
      digits = digits.slice(0, -(digits.length - 13));
    }

    return digits.join("");
  };

  const handlePhoneNumberOnChange = (newValue) => {
    const digits = toNumberOnly(newValue.split(""));
    setPhone(formatPhoneNumber(digits));
    setWarning({
      ...warning,
      phone: false,
    });
  };

  const handleInputOnChange = (newValue, field, setValue) => {
    const WARNING_FIELDS = ["firstName", "lastName", "email", "message"];

    if (WARNING_FIELDS.indexOf(field) !== -1) {
      setWarning({
        ...warning,
        [field]: false,
      });
    }

    setValue(newValue);
  };

  return (
    <div className={styles.container}>
      <h2>
        <div>WE ARE HERE FOR YOU</div>
        <div>
          {type === "appointment"
            ? "Request An Appointment"
            : "Contact Us Message"}
        </div>
      </h2>
      <form className={styles.form} autoComplete="off">
        <div className={styles.inputContainer}>
          <label htmlFor="fName">
            First Name <span>*</span>
          </label>
          <input
            type="text"
            name="fName"
            id="fName"
            placeholder="First Name"
            autoComplete="new-password"
            value={firstName}
            onChange={(e) =>
              handleInputOnChange(e.target.value, "firstName", setFirstName)
            }
            className={warning.firstName ? styles.warningInput : ""}
          />
          {warning.firstName && (
            <p className={styles.warning}>This field is required.</p>
          )}
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="lName">
            Last Name <span>*</span>
          </label>
          <input
            type="text"
            name="lName"
            id="lName"
            placeholder="Last Name"
            autoComplete="new-password"
            value={lastName}
            onChange={(e) =>
              handleInputOnChange(e.target.value, "lastName", setLastName)
            }
            className={warning.lastName ? styles.warningInput : ""}
          />
          {warning.lastName && (
            <p className={styles.warning}>This field is required.</p>
          )}
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="contactEmail">
            Email Address <span>*</span>
          </label>
          <input
            type="email"
            name="contactEmail"
            id="contactEmail"
            placeholder="Email"
            autoComplete="new-password"
            value={email}
            onChange={(e) =>
              handleInputOnChange(e.target.value, "email", setEmail)
            }
            className={warning.email ? styles.warningInput : ""}
          />
          {warning.email && <p className={styles.warning}>Invalid Email.</p>}
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="contactPhone">
            Phone Number <span>*</span>
          </label>
          <input
            type="text"
            name="contactPhone"
            id="contactPhone"
            placeholder="Phone Number"
            autoComplete="new-password"
            value={phone}
            onChange={(e) => handlePhoneNumberOnChange(e.target.value)}
            className={warning.phone ? styles.warningInput : ""}
          />
          {warning.phone && (
            <p className={styles.warning}>Invalid phone number.</p>
          )}
        </div>
        {type === "appointment" && (
          <>
            <div className={styles.inputContainer}>
              <label htmlFor="appointmentDate">Preferred Appoinment Date</label>
              <input
                type="text"
                name="appointmentDate"
                id="appointmentDate"
                placeholder="Preferred Appointment Date"
                autoComplete="new-password"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="time">Preferred Appointment Time</label>
              <input
                type="text"
                name="time"
                id="time"
                placeholder="Preferred Appointment Time"
                autoComplete="new-password"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
          </>
        )}
        <div className={styles.textarea}>
          <label htmlFor="message">
            How can we help you? <span>*</span>
          </label>
          <textarea
            name="message"
            id="message"
            cols="0"
            rows="5"
            value={message}
            onChange={(e) =>
              handleInputOnChange(e.target.value, "message", setMessage)
            }
            className={warning.message ? styles.warningTextArea : ""}
          ></textarea>
          {warning.message && (
            <p className={styles.warning}>This field is required.</p>
          )}
        </div>
        <button className={styles.button} onClick={(e) => handleSubmit(e)}>
          Submit
        </button>
      </form>
      {isSubmitted && (
        <div className={styles.overlay}>
          {isSent ? (
            <div className={styles.successCircle}>
              <AiOutlineCheck />
            </div>
          ) : (
            <div className={styles.loadingCircle}></div>
          )}
          <p>
            {isSent ? (
              <>
                Your request has been sent. <br /> Thank you!
              </>
            ) : (
              "Sending Request..."
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default Form;
