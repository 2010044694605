import styles from "./Menu.module.css";

import { NavLink } from "react-router-dom";

const Menu = ({ hideMenu }) => {
  return (
    <ul className={styles.container}>
      <li onClick={hideMenu}>
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? styles.activeNavLink : styles.navLink
          }
        >
          Home
        </NavLink>
      </li>
      <li onClick={hideMenu}>
        <NavLink
          to="/services"
          className={({ isActive }) =>
            isActive ? styles.activeNavLink : styles.navLink
          }
        >
          Services
        </NavLink>
      </li>
      <li onClick={hideMenu}>
        <NavLink
          to="/contact"
          className={({ isActive }) =>
            isActive ? styles.activeNavLink : styles.navLink
          }
        >
          Contact Us
        </NavLink>
      </li>
      <li onClick={hideMenu}>
        <NavLink to="/request-appointment" className="linkBtn scheduleBtn">
          Schedule Now
        </NavLink>
      </li>
    </ul>
  );
};

export default Menu;
