import styles from "./Services.module.css";

import { services } from "../data";
import ServiceTile from "./ServiceTile";

const Services = ({ title, currentService }) => {
  const renderTiles = () => {
    const serviceList = Object.keys(services).filter(
      (service) => service !== currentService
    );

    return serviceList.map((id) => (
      <ServiceTile key={"tile" + id} service={services[id]} />
    ));
  };

  return (
    <section className={styles.section}>
      <h2>{title}</h2>
      <div className={styles.articles}>{renderTiles()}</div>
    </section>
  );
};

export default Services;
