import styles from "./App.module.css";

import { TbArrowBigUpLine } from "react-icons/tb";
import { useEffect, useState } from "react";

import Header from "./Header";
import Footer from "./Footer";
import OfficeInfo from "./OfficeInfo";
import { Route, Routes, useLocation } from "react-router-dom";
import Services from "./Services";
import ContactPage from "./ContactPage";
import HomePage from "./HomePage";
import ServicePage from "./ServicePage";

const SHOW_ON_PX = 100;

const App = () => {
  const [showBackToTopBtn, setShowBackToTopBtn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleShowBackToTopBtn = () => {
      const scrollContainer = document.documentElement || document.body;
      const scrollPx = scrollContainer.scrollTop;
      if (scrollPx > SHOW_ON_PX) {
        setShowBackToTopBtn(true);
      } else {
        setShowBackToTopBtn(false);
      }
    };

    window.addEventListener("scroll", handleShowBackToTopBtn);

    return () => window.removeEventListener("scroll", handleShowBackToTopBtn);
  }, []);

  const scrollToTop = () => {
    const scrollContainer = document.documentElement || document.body;
    scrollContainer.scrollTop = 0;
  };

  useEffect(() => {
    scrollToTop();
  }, [location]);

  return (
    <div className={styles.container}>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/services"
          element={
            <Services title="Comprehensive care, one convenient location" />
          }
        />
        <Route path="/services/:id" element={<ServicePage />} />
        <Route path="/contact" element={<ContactPage type="contact" />} />
        <Route
          path="/request-appointment"
          element={<ContactPage type="appointment" />}
        />
      </Routes>
      <OfficeInfo />
      <Footer />
      <div className="borderLine"></div>
      <button
        className={`${styles.backToTopBtn} ${
          showBackToTopBtn ? styles.showBackToTopBtn : ""
        }`}
        onClick={scrollToTop}
      >
        <TbArrowBigUpLine />
      </button>
    </div>
  );
};

export default App;
