export const services = {
  "cosmetic-dentistry": {
    id: "cosmetic-dentistry",
    title: "Cosmetic Dentistry",
    imgLink: "/services/cosmetic.jpg",
    details: [
      "Porcelain Crowns & Veneers",
      "Esthetic tooth-coloured fillings",
      "Zoom! II Teeth Whitening (In-Clinic)",
      "Professional take-home Teeth Whitening (NiteWhite®)",
    ],
  },
  "general-and-restorative-dentistry": {
    id: "general-and-restorative-dentistry",
    title: "General And Restorative Dentistry",
    imgLink: "/services/general.jpg",
    details: [
      "Regular Dental Exam & Cleaning",
      "Digital Radiographs/X-Rays",
      "Oral Cancer Screenings (VELscope Technology)",
      "Restorations/Fillings",
      "Periodontal Therapy (Scaling & Root Planing)",
      "Emergency Dental Treatment",
      "Root Canal Therapy",
      "Sensitive Teeth Treatment",
      "Night Guards",
      "Dentistry for Kids",
    ],
  },
  "implant-dentistry": {
    id: "implant-dentistry",
    title: "Implant Dentistry",
    imgLink: "/services/implant.jpg",
    details: [
      "Dental Implants provide the look & feel of natural teeth",
      "Nobel Biocare Implant System offers the most reliable and aesthetic results",
      "Replace a single tooth or several missing teeth",
    ],
  },
  "oral-surgery": {
    id: "oral-surgery",
    title: "Oral Surgery",
    imgLink: "/services/oral-surgery.jpg",
    details: [
      "Periodontal (Gum) Surgery",
      "Tooth Extraction",
      "Wisdom Teeth Extractions",
      "Tissue & Bone Grafting",
    ],
  },
  orthodontics: {
    id: "orthodontics",
    title: "Orthodontics",
    imgLink: "/services/orthodontics.jpg",
    details: [
      "Orthodontic treatment for children & adults",
      "Conventional & Clear Braces",
    ],
  },
  prosthodontics: {
    id: "prosthodontics",
    title: "Prosthodontics",
    imgLink: "/services/prosthodontics.jpg",
    details: ["Dental Bridges", "Complete Dentures", "Partial Dentures"],
  },
};
